import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FormCard from "../../../components/formCard/FormCard";
import RadioInput from "../../../components/helper/RadioInput";
import { insuranceSelectors } from "../../../selectors";

import lang from "../../../helpers/lang-constants";
import insuranceNameList from "../constants/insuranceList";

const language =
  lang.customer.views.claim.questions.question_confirm_insurance_company;

const { getUpdating, getVehicleInsurance } = insuranceSelectors;

function QuestionConfirmInsuranceCompany(props) {
  const [insuranceNameListState, setInsuranceNameListState] =
    useState(insuranceNameList);
  const [showCustomInsuranceList, setShowCustomInsuranceList] = useState(false);
  const [showCustomInsurance, setShowCustomInsurance] = useState(false);
  const [showRadiobuttons, setShowRadiobuttons] = useState(false);

  useEffect(() => {
    const sortedInsuranceCompanies = insuranceNameList.sort((a, b) =>
      a > b ? 1 : -1
    );
    setInsuranceNameListState(sortedInsuranceCompanies);
  }, [insuranceNameListState]);

  const onChangeRadiobuttonHandler = (value) => {
    if (value === "no") {
      props.setState((prevState) => ({ ...prevState, radioState: value }));
      setShowCustomInsuranceList(true);
    } else if (value === "yes") {
      props.setState({
        insuranceCompany: props.vehicleInsurance.insuranceCompanyName,
        radioState: value,
      });
      props.showNextQuestion(props.name);
      setShowCustomInsuranceList(false);
      setShowCustomInsurance(false);
    }
  };

  const onChangeInsuranceList = (event) => {
    const insuranceCompany = event.target.value;
    if (insuranceCompany === "Annat") {
      setShowCustomInsurance(true);
    } else {
      setShowCustomInsurance(false);
      props.setState((prevState) => ({
        ...prevState,
        insuranceCompany,
      }));
      props.showNextQuestion(props.name);
    }
  };

  const onChangeInputHandler = (event) => {
    const insuranceCompany = event.target.value;
    props.setState((prevState) => ({
      ...prevState,
      insuranceCompany,
    }));
    props.showNextQuestion(props.name);
  };

  let insuranceInformation = "";
  if (!props.vehicleInsurance.noInsurance) {
    if (props.isLoading) {
      insuranceInformation = language.fetching_insurance;
      if (showCustomInsurance) {
        setShowCustomInsurance(false);
      }
      if (showRadiobuttons) {
        setShowRadiobuttons(false);
      }
    } else if (
      props.vehicleInsurance.insuranceCompanyName === "OFÖRSÄ" ||
      props.vehicleInsurance.insuranceCompanyName === ""
    ) {
      insuranceInformation = (
        <div className="claim-insurance-information">
          <p>
            Vi verkar inte hitta ditt försäkringsbolag. Vänligen välj i listan
            nedan.
          </p>
        </div>
      );
      if (!showCustomInsuranceList) {
        setShowCustomInsuranceList(true);
      }
    } else if (
      props.vehicleInsurance.insuranceCompanyName &&
      props.vehicleInsurance.insuranceCompanyName !== "" &&
      props.vehicleInsurance.insuranceCompanyName !== "OFÖRSÄ"
    ) {
      insuranceInformation = (
        <div className="claim-insurance-information">
          <p>
            {language.question_confirm_prefix}
            <span>{props.vehicleInsurance.insuranceCompanyName}</span>
            {language.question_confirm_suffix}
          </p>
        </div>
      );
      if (!showRadiobuttons) {
        setShowRadiobuttons(true);
      }
    }
  } else if (props.vehicleInsurance.noInsurance) {
    insuranceInformation = (
      <div className="claim-insurance-information">
        <p>{language.could_not_fetch_insurance}</p>
      </div>
    );
    if (!showCustomInsurance) {
      setShowCustomInsurance(true);
    }
  }

  const InsuranceCompanies = insuranceNameListState.map((alternative) => (
    <option value={alternative} key={alternative}>
      {alternative}
    </option>
  ));

  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title={language.title_text}
      description={language.description_text}
    >
      {insuranceInformation}
      <div className="confirm-insurance-container">
        {showRadiobuttons && (
          <div className="radio-container">
            <RadioInput
              value="yes"
              selected={props.state.radioState}
              text={language.alternative_confirm}
              onChange={onChangeRadiobuttonHandler}
            />
            <RadioInput
              value="no"
              selected={props.state.radioState}
              text={language.alternative_decline}
              onChange={onChangeRadiobuttonHandler}
            />
          </div>
        )}
        {showCustomInsuranceList && (
          <>
            <hr className="horizontal-divider" />
            <div className="damage-type-select-container">
              <label htmlFor="QuestionDamageTypeGroup">
                <select
                  className="insurance-company-select"
                  name="CustomInsuranceList"
                  id="CustomInsuranceList"
                  onChange={onChangeInsuranceList}
                  defaultValue="initialValue"
                >
                  <option value="initialValue" disabled hidden>
                    Var god välj försäkringsbolag
                  </option>
                  {InsuranceCompanies}
                </select>
              </label>
            </div>
          </>
        )}
        {showCustomInsurance && (
          <>
            <hr className="horizontal-divider" />
            <div className="custom-insurance-company-container">
              <label htmlFor="custom-insurance-company-input">
                {language.question_insurance_company_name}
              </label>
              <input
                id="customInsuranceCompanyInput"
                className="custom-insurance-company"
                type="text"
                onChange={onChangeInputHandler}
              />
            </div>
          </>
        )}
      </div>
    </FormCard>
  );
}

const mapStateToProps = (state) => ({
  isLoading: getUpdating(state, "vehicleInsurance"),
  vehicleInsurance: getVehicleInsurance(state).toJSON(),
});

export default connect(mapStateToProps)(QuestionConfirmInsuranceCompany);
