const getAccessToken = async () => {
  const url = "https://auth.finshark.io/connect/token";

  const finsharkClientId = "c0801b50-f92e-a7ee-eadc-f6df42f95818";
  const finsharkClientSecret = "41055e9f-6561-9a74-9855-d1a882330994";

  const body = new URLSearchParams({
    client_id: finsharkClientId,
    client_secret: finsharkClientSecret,
    grant_type: "client_credentials",
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error("Failed to fetch access token:", error);
  }
};

export const fetchFinsharkUrl = async (ssn) => {
  const token = await getAccessToken();
  try {
    const payload = {
      region: "SE",
      locale: "SE",
      type: "accountVerification",
      ssn,
      iframe: true,
      appUri: `${window.location.origin}/skadeanmalan`,
      bankUrisInNewTab: true,
    };

    const response = await fetch("https://api.finshark.io/v3/sessions", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Failed to fetch Finshark URL:", error);
  }
};

export const fetchFinsharkAccountInformation = async (messageData) => {
  try {
    const token = await getAccessToken(); // Hämta din access token först
    if (!token) {
      throw new Error("No access token available");
    }

    const response = await fetch(
      `https://api.finshark.io/v2/verified-accounts/${messageData.verifiedAccountId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          authorizationId: messageData.authorizationId,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const accountData = await response.json();
    return accountData;
  } catch (error) {
    console.error("Failed to fetch account information:", error);
  }
};
