import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import FormCard from "../../../components/formCard/FormCard";
import Button from "../../../components/helper/Button";
import lang from "../../../helpers/lang-constants";
import { getQuestionErrorMessage } from "../../../helpers/utils";
import { insuranceSelectors, userSelectors } from "../../../selectors";
import {
  fetchFinsharkAccountInformation,
  fetchFinsharkUrl,
} from "../../../services/finshark.service";
import insuranceActions from "../../../actions/insurance.actions";
import { substringResponse } from "../../../helpers/formatter";

const language = lang.customer.views.claim.questions.question_bank;
const { getKlarnaOpenBankFetchStatus, getKlarnaOpenBankAccountInformation } =
  insuranceSelectors;

const { getUserData } = userSelectors;

const { klarnaOpenBankingFetchDataSuccess } = insuranceActions;

const bankValidator = (value, type, id) => {
  let errorMessage;
  let validValue;

  if (type === "number" && id === "swish") {
    validValue = /^[0][7][0-9]{8}$/gm;
  } else if (type === "number") {
    validValue = /^[0-9]+$/gm;
  } else if (type === "text") {
    validValue = /[a-zA-Z]/gm;
  }
  const valid = validValue.test(value);
  if (!valid) {
    errorMessage =
      id === "swish" ? language.error_message_swish : language.error_message;
  }
  return errorMessage;
};

function QuestionBank(props) {
  const [iframeUrl, setIframeUrl] = useState(null);
  const [showFinshark, setShowFinshark] = useState(false);
  const iFrameRef = useRef(null);

  //! START - TEMPORARILY ENABLED - REMOVE WHEN ENABLING SWISH PAYMENT
  useEffect(() => {
    props.setState((prevState) => ({ ...prevState, paymentMethod: "USK" }));
  }, []);
  //! END - TEMPORARILY ENABLED - REMOVE WHEN ENABLING SWISH PAYMENT

  const onChangeHandler = (event) => {
    const { value, id, type } = event.target;
    const validationMessage = bankValidator(value, type, id);
    props.setState((prevState) => ({ ...prevState, [id]: value }));
    props.showNextQuestion(props.name, validationMessage);
  };

  const { userData, dispatch } = props;

  const customerCode = userData.get("customerCode");

  const formatCustomerCode = (customerCode) => {
    let formattedCode;

    const firstTwoDigits = parseInt(customerCode.substring(0, 2), 10);

    if (firstTwoDigits >= 0 && firstTwoDigits <= 20) {
      formattedCode = `20${customerCode}`;
    } else if (firstTwoDigits >= 21 && firstTwoDigits <= 99) {
      formattedCode = `19${customerCode}`;
    }

    return formattedCode;
  };

  const handleFetchCustomer = () => {
    const formattedCustomerCode = formatCustomerCode(customerCode);
    fetchFinsharkUrl(formattedCustomerCode)
      .then((data) => {
        setIframeUrl(data.url);
        setShowFinshark(true);
      })
      .catch((error) => {
        console.error("Failed to fetch Finshark URL:", error);
      });
  };

  useEffect(() => {
    const handlePostMessage = (event) => {
      if (event.origin !== "https://flows.finshark.io") {
        return;
      }

      const messageData = event.data;

      if (messageData.event === "redirect") {
        window.open(messageData.redirectLink, "_blank");
      }

      if (messageData.event === "deeplink") {
        window.location.href = messageData.deeplink;
      }

      if (messageData.result === "Canceled") {
        setIframeUrl("");
        setShowFinshark(false);
      }

      if (messageData.result === "Success") {
        setIframeUrl("");
        setShowFinshark(false);

        fetchFinsharkAccountInformation(messageData)
          .then((accountData) => {
            props.setState((prevState) => ({
              ...prevState,
              bankName: accountData.providerId,
              clearingNumber: accountData.clearingNumber,
              accountNumber: substringResponse(
                accountData.accountNumber,
                accountData.clearingNumber
              ),
              owner: accountData.ownerName,
            }));
            return accountData;
          })
          .then((accountData) => {
            dispatch(klarnaOpenBankingFetchDataSuccess(accountData));
          });
      }
    };

    window.addEventListener("message", handlePostMessage);

    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  useEffect(() => {
    if (props.state.bankName && props.state.accountNumber) {
      props.showNextQuestion(props.name);
    }
  }, [props.state.bankName, props.state.accountNumber]);

  useEffect(() => {
    if (showFinshark && iFrameRef.current) {
      iFrameRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showFinshark]);

  const paymentPicker = () => {
    switch (props.state.paymentMethod) {
      case "USK":
        const buttonText = props.accountInformation.accountNumber
          ? "Hämta andra uppgifter"
          : language.fetch_klarna_button;
        return (
          <div className="bank-input-wr">
            <h6>{language.description_bank_text}</h6>
            <Button
              type="button"
              className="button button-primary sign-insurance"
              onClick={handleFetchCustomer}
            >
              {buttonText}
            </Button>
          </div>
        );
      case "USS":
        return (
          <div>
            <h6>{language.description_swish_text}</h6>
            <label htmlFor="QuestionOwner">
              <p>{language.swish}</p>
              <input
                className="input-claim-bank"
                type="number"
                name="QuestionSwish"
                id="swish"
                placeholder="Telefonnr"
                onChange={onChangeHandler}
              />
            </label>
          </div>
        );
      default:
        return <div />;
    }
  };

  const paymentInformation = paymentPicker();
  return (
    <>
      <div ref={iFrameRef} />
      <FormCard
        showList={props.showList}
        name={props.name}
        title={language.title_text}
      >
        {paymentInformation}
        {showFinshark && (
          <iframe
            title="Finshark"
            src={iframeUrl}
            className="iframe-container "
          />
        )}
        {props.accountInformation.accountNumber && (
          <>
            <p className="bank-info">{language.indicated_account}</p>
            <span>
              {language.clearing + props.accountInformation.clearingNumber}
            </span>
            <br />
            <span>
              {language.account +
                substringResponse(
                  props.accountInformation.accountNumber,
                  props.accountInformation.clearingNumber
                )}
            </span>
          </>
        )}
        {props.fetchKlarnaInfoFailed && (
          <p className="questions-error-message">
            {language.error_message_klarna}
          </p>
        )}
        <span className="questions-error-message">
          {getQuestionErrorMessage(props.showList, props.name) || " "}
        </span>
      </FormCard>
    </>
  );
}

const mapStateToProps = (state) => ({
  fetchKlarnaInfoFailed: getKlarnaOpenBankFetchStatus(state),
  accountInformation: getKlarnaOpenBankAccountInformation(state).toJSON(),
  userData: getUserData(state),
});

export default connect(mapStateToProps)(QuestionBank);
