import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import QuestionInsuranceType from "./Questions/QuestionInsuranceType";
import QuestionVehicle from "./Questions/QuestionVehicle";
import QuestionDateOfAccident from "./Questions/QuestionDateOfAccident";
import QuestionConfirmInsuranceCompany from "./Questions/QuestionConfirmInsuranceCompany";
import QuestionDamageType from "./Questions/QuestionDamageType";
import QuestionCost from "./Questions/QuestionCost";
import QuestionAddAttachment from "./Questions/QuestionAddAttachment";
import QuestionAddAdditionalInfo from "./Questions/QuestionAddAdditionalInfo";
import { insuranceActions } from "../../actions";
import Button from "../../components/helper/Button";

import ClaimQuestionWrapper from "./ClaimQuestionWrapper";
import lang from "../../helpers/lang-constants";
import QuestionLegalConfirmation from "./Questions/QuestionLegalConfirmation";
import QuestionBank from "./Questions/QuestionBank";
import QuestionCollision from "./Questions/QuestionCollision";
import ContactInformation from "./ContactInformation";
import { insuranceSelectors } from "../../selectors";
import { substringResponse } from "../../helpers/formatter";

const language = lang.customer.views.claim.claim_form;

const { getKlarnaOpenBankAccountInformation } = insuranceSelectors;

const initialAnsweredQuestions = [
  {
    name: "QuestionLegalConfirmation",
    show: true,
    answered: false,
    order: 0,
    errorMessage: null,
  },
];

function ClaimForm(props) {
  useEffect(() => {
    props.postClaimInsuranceCleanup();
  }, []);

  const [questionsListState, setQuestionListState] = useState(
    initialAnsweredQuestions
  );

  const [QuestionLegalConfirmationState, setQuestionLegalConfirmationState] =
    useState([false, false]);
  const [QuestionInsuranceTypeState, setQuestionInsuranceTypeState] =
    useState("");
  const [QuestionVehicleState, setQuestionVehicleState] = useState();
  const [QuestionDateOfAccidentState, setQuestionDateOfAccidentState] =
    useState({ date: "", time: "" });
  const [
    QuestionConfirmInsuranceCompanyState,
    setQuestionConfirmInsuranceCompanyState,
  ] = useState({ radioState: "", insuranceCompany: "" });
  const [QuestionCollisionState, setQuestionCollisionState] = useState({
    radioState: "",
  });
  const [QuestionDamageTypeState, setQuestionDamageTypeState] = useState();
  const [QuestionCostState, setQuestionCostState] = useState();
  const [QuestionBankState, setQuestionBankState] = useState({
    paymentMethod: "",
    bankName: "",
    clearingNumber: "",
    accountNumber: "",
    owner: "",
    swishNumber: "",
  });
  const [QuestionAddAttachmentState, setQuestionAddAttachmentState] = useState({
    guid: "",
    attachedFiles: [],
  });
  const [QuestionAddAdditionalInfoState, setQuestionAddAdditionalInfoState] =
    useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const checkValidationStatus = () => {
    const filteredQuestions = questionsListState.filter(
      (item) => item.name !== "QuestionAddAdditionalInfo"
    );
    const finalizeStatus = filteredQuestions.reduce((_, item) => {
      const { optional, answered } = item;
      if (_ === false) return false;
      if (answered) {
        return true;
      }
      if (optional) {
        return true;
      }
      return false;
    }, undefined);
    return finalizeStatus;
  };

  const updateShowNextQuestion = (name, validationMessage) => {
    const index = questionsListState.findIndex((question) => {
      return question.name === name;
    });

    const updatedQuestionListState = [...questionsListState];
    if (!validationMessage) {
      updatedQuestionListState[index].answered = true;
      updatedQuestionListState[index].errorMessage = null;
      if (index < questionsListState.length - 1) {
        updatedQuestionListState[index + 1].show = true;
      }
    } else {
      updatedQuestionListState[index].answered = false;
      updatedQuestionListState[index].errorMessage = validationMessage;
    }

    setQuestionListState(updatedQuestionListState);
    setErrorMessage("");

    if (checkValidationStatus()) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  };

  const createReceipts = () => {
    return QuestionAddAttachmentState.attachedFiles.reduce((prev, curr, i) => {
      return i === 0 ? `${curr.name}` : `${prev}, ${curr.name}`;
    }, "");
  };

  const formatDate = () =>
    QuestionDateOfAccidentState?.date.replaceAll("-", "").slice(2);

  const combineState = () => {
    const receipts = createReceipts();
    const formattedDate = formatDate();
    const swishNumber = QuestionBankState.swish
      ? `46${QuestionBankState.swish.substring(1)}`
      : "";
    const combinedState = {
      regnr: QuestionVehicleState,
      damageDate: formattedDate,
      damageTime: QuestionDateOfAccidentState.time,
      damageType: QuestionDamageTypeState,
      cost: QuestionCostState,
      receipt1: QuestionAddAttachmentState.guid,
      receipt2: receipts,
      damageReason: `${QuestionConfirmInsuranceCompanyState.insuranceCompany}, ${QuestionAddAdditionalInfoState}`,
      inflictingParty: QuestionCollisionState,
      clearingNum: props.accountInformation?.clearingNumber,
      accountNum: substringResponse(
        props.accountInformation?.accountNumber,
        props.accountInformation?.clearingNumber
      ),
      payMethod: QuestionBankState.paymentMethod,
      mobile: swishNumber,
    };
    return combinedState;
  };

  const validateBankInformation = () => {
    if (QuestionBankState.paymentMethod === "USS" && QuestionBankState.swish) {
      return true;
    }
    if (
      QuestionBankState.paymentMethod === "USK" &&
      props.accountInformation?.clearingNumber
    ) {
      return true;
    }
    return false;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (validateBankInformation()) {
      const statesForBackend = combineState();
      window.scrollTo(0, 0);
      props.showResponse(true);
      props.postClaimInsurance(statesForBackend);
    } else {
      setErrorMessage(
        "Vi saknar tyvärr din bankinformation, var god följ instruktionerna från Klarna och hämta hem din bankinformation eller skriv in ditt mobilnummer"
      );
    }
  };

  return (
    <>
      <ContactInformation />
      <form className="claim" onSubmit={(event) => submitHandler(event)}>
        <ClaimQuestionWrapper setQuestionList={setQuestionListState}>
          <QuestionLegalConfirmation
            name="QuestionLegalConfirmation"
            state={QuestionLegalConfirmationState}
            setState={setQuestionLegalConfirmationState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionInsuranceType
            name="QuestionInsuranceType"
            state={QuestionInsuranceTypeState}
            setState={setQuestionInsuranceTypeState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionVehicle
            name="QuestionVehicle"
            state={QuestionVehicleState}
            setState={setQuestionVehicleState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionDateOfAccident
            state={QuestionDateOfAccidentState}
            setState={setQuestionDateOfAccidentState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionConfirmInsuranceCompany
            name="QuestionConfirmInsuranceCompany"
            state={QuestionConfirmInsuranceCompanyState}
            setState={setQuestionConfirmInsuranceCompanyState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionDamageType
            name="QuestionDamageType"
            state={QuestionDamageTypeState}
            setState={setQuestionDamageTypeState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionCollision
            name="QuestionCollision"
            state={QuestionCollisionState}
            setState={setQuestionCollisionState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionCost
            name="QuestionCost"
            state={QuestionCostState}
            setState={setQuestionCostState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionAddAttachment
            name="QuestionAddAttachment"
            state={QuestionAddAttachmentState}
            setState={setQuestionAddAttachmentState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionBank
            name="QuestionBank"
            state={QuestionBankState}
            setState={setQuestionBankState}
            showList={questionsListState}
            showNextQuestion={updateShowNextQuestion}
          />
          <QuestionAddAdditionalInfo
            name="QuestionAddAdditionalInfo"
            state={QuestionAddAdditionalInfoState}
            setState={setQuestionAddAdditionalInfoState}
            showList={questionsListState}
            enableSubmit={setEnableSubmit}
            showNextQuestion={updateShowNextQuestion}
          />
        </ClaimQuestionWrapper>
        <div className="submit-button">
          <Button type="submit" btnType="primary" disabled={!enableSubmit}>
            {language.submit_button}
          </Button>
          <span className="questions-error-message">{errorMessage || " "}</span>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state) => ({
  accountInformation: getKlarnaOpenBankAccountInformation(state).toJSON(),
});

const mapDispatchToProps = (dispatch) => ({
  postClaimInsurance: (formState) =>
    dispatch(insuranceActions.postClaimInsurance(formState)),
  postClaimInsuranceCleanup: () =>
    dispatch(insuranceActions.postClaimInsuranceCleanup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimForm);
