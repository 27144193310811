import dateFormat from "dateformat";
import { themes } from "./config";

export default function formatDate(date) {
  let format;
  if (themes.isNorway()) {
    format = "dd.mm.yyyy";
  } else {
    format = "yyyy-mm-dd";
  }

  return dateFormat(date, format);
}

export const substringResponse = (accountNumber, clearingNumber) => {
  let sanitizedAccountNumber = accountNumber.replace(/[\s-]+/g, "");

  const commaIndex = sanitizedAccountNumber.indexOf(",");
  if (commaIndex !== -1) {
    sanitizedAccountNumber = sanitizedAccountNumber.substring(commaIndex + 1);
  }

  if (sanitizedAccountNumber.startsWith(clearingNumber)) {
    sanitizedAccountNumber = sanitizedAccountNumber.substring(
      clearingNumber.length
    );
  }

  return sanitizedAccountNumber;
};
